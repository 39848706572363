<template>
  <div class="daily-info three" v-if="model">
    <div class="item">
      <div class="image">
        <img class="effect3" :src="imagePath" />
      </div>
      <div class="item-text" style="padding-left:7px;">
        <span style="font-size:40px">{{ model.weatherValue }}° <small>HAVA<br />DURUMU</small></span>
      </div>
      <!-- <div class="image fixed-image">
        <img :src="imagePath" />
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'weather3',
  props: ['data'],
  computed: {
    model() {
      return this.$store.state.weather;
    },
    imagePath() {
      return `/images/weather/${this.model.weatherIcon}`;
    }
  }
};
</script>

<style scoped>
</style>
